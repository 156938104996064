<template>
  <div class="switch-camera">
    <h4>Audio and Video Settings</h4>
    <h3>Switch preferred camera and video on Computer</h3>
    <div class="test-block" id="settings-camera-video"></div>
    <div class="test-block-action">
      <div class="action-column camera">
        <select class="selectDropdown" id="camera-setting-dropdown"> </select>
      </div>
      <div class="action-column audio">
        <select class="selectDropdown" id="mic-setting-dropdown"> </select>
      </div>
    </div>
    <p class="text-center">
      If you face any issues, try restarting your system. We have an FAQ page
      here to troubleshoot system settings, internet connection here.
    </p>
  </div>
</template>
