<template>
  <div class="participants-table mt-1">
    <div class="container">
      <div class="owl-carousel active_table owl-loaded owl-drag">
        <div class="owl-stage-outer">
          <h6>
            TABLES AVAILABLE FOR THIS MEETING (The seats in gray are already
            taken)
          </h6>
          <split-carousel
            :autoplay="false"
            :arrow-visible="true"
            display-amount="5"
            style="width: 1000px;height:170px"
          >
            <split-carousel-item
              style="padding: 25px;margin-left: 68px"
              v-for="(data, index) in tables"
              :key="index"
            >
              <div class="seat-setting">
                <div class="column-top">
                  <div class="seat"></div>
                  <div class="seat"></div>
                </div>
                <div class="column-center">
                  <div class="seat left"></div>
                  <div :id="`table_title_${data.id}`" class="seat seat-table ">
                    {{ data.table_title }}
                  </div>
                  <div class="seat right"></div>
                </div>
                <div class="column-bottom">
                  <div class="seat"></div>
                  <div class="seat"></div>
                </div>
                <div
                  :class="`seat-taken`"
                  style="visibility:hidden"
                  :id="`seat_taken_${index}`"
                >
                  <h5>
                    <input
                      :id="`table_timer_${data.id}`"
                      class="w-auto table-timer-<?=$model->table->id?> "
                      type="number"
                      :min="0"
                      :value="data.table_timer"
                      @keyup="
                        $event.target.value > 9999
                          ? saveTimer(9999, data)
                          : $event.target.value <= 0
                          ? saveTimer(0, data)
                          : saveTimer($event.target.value, data)
                      "
                      :max="9999"
                    />
                    seconds
                    <button
                      id="table-<?=$model->table->id?>"
                      class="btn-primary btn-table m-0"
                      onclick="adminSeat(<?=$model->table->id?>, seatNotOccupied)"
                    >
                      Join
                    </button>
                    <div>
                      <a
                        href="javascript:void(0)"
                        @click="edit(`seat_taken_${index}`, index)"
                        v-if="!editMode"
                        >Edit</a
                      >
                      <a
                        href="javascript:void(0)"
                        @click="saveTableTitle(`seat_taken_${index}`, data)"
                        v-if="editMode && index == editindex"
                        >Save</a
                      >
                      <span
                        v-if="!editMode"
                        :id="`table_title_${data.id}_hover`"
                        style="display: inline-block; min-width: 85%"
                      >
                        {{ data.table_title }}
                      </span>
                      <span style="display: inline-block; min-width: 85%">
                        <input
                          v-model="data.table_title"
                          v-if="editMode && index == editindex"
                        />
                      </span>
                    </div>
                    <ul>
                      <li
                        v-for="(seat, index) in data.seats"
                        :key="index"
                      >
                        <a
                          id="seat<?=$seat->id?>"
                          class="btn btn-primary seat"
                          @click="
                            showModal = true;
                            changeSeatStatus(
                              seat.id,
                              seat.table_no,
                              seat.seat_no,
                              seatNotOccupied
                            );
                          "
                          :title="seat.seat_no"
                          >{{ index + 1 }}</a
                        >
                        <!-- changeSeatStatus(<?=$seat->id?>,<?=$seat->table_no?>, <?=$seat->seat_no?>, '<?=$seat->title?>', seatNotOccupied) -->
                      </li>
                    </ul>
                    <h5 class="mt-2">(The seats in gray are already taken)</h5>
                  </h5>
                </div>
              </div>
            </split-carousel-item>
          </split-carousel>
        </div>
      </div>
    </div>
    <TestMicCamera
      :showModal="showModal"
      v-if="showModal"
      :id="test_video_modal"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
import TestMicCamera from "./test_mic_camera.vue";
import agoraRTC from "../../plugins/agora-rtc-client.js";
export default {
  components: {
    SplitCarousel,
    SplitCarouselItem,
    TestMicCamera,
  },
  data: () => ({
    display: false,
    editMode: false,
    seatNotOccupied: 0,
    seatOccupied: 1,
    editindex: "",
    showModal: false,
  }),
  computed: {
    tables() {
      return this.$store.state.auth.tables;
    },
    seats() {
      return this.$store.state.auth.seats;
    },
    event() {
      return this.$store.state.auth.event;
    },
  },
  created() {
    this.rtc = new agoraRTC()

    // receiving table title from socket
    this.$soketio.on("table_title", function(data) {
      if (data.title)
        document.getElementById("table_title_" + data.id).textContent =
          data.title;
      document.getElementById("table_title_" + data.id + "_hover").textContent =
        data.title;
    });
    // receiving table timer from socket
    this.$soketio.on("table_timer", function(data) {
      console.log("receoved" + data);
      if (data.timer)
        document.getElementById("table_timer_" + data.id).value = data.timer;
    });
  },
  methods: {
    // enable input for edit title
    edit(data, index) {
      this.editMode = true;
      this.editindex = index;
      document.getElementById(data).style.visibility = "visible";
    },
    // save table title
    saveTableTitle(div_id, table_data) {
      this.editMode = false;
      document.getElementById(div_id).style.visibility = "hidden";
      this.$store
        .dispatch("lobby/saveTableTitle", table_data, this.API_URL)
        .then((r) => {
          console.log(r);
          if (r) {
            let data = {
              id: table_data.id,
              room: this.event.id,
              title: r.table_title,
            };
            console.log(data);
            this.$soketio.emit("table_title", data);
          } else {
            alert("Try Again");
          }
        });
    },
    // save table timer
    saveTimer(val, table_data) {
      table_data.table_timer = val;
      this.$store.dispatch("lobby/saveTimer", table_data).then((r) => {
        console.log(r);
        if (r.table_timer) {
          let data = {
            id: table_data.id,
            room: this.event.id,
            timer: r.table_timer,
          };
          this.$soketio.emit("table_timer", data);
        } else {
          alert("Try Again");
        }
      });
    },
    //
    changeSeatStatus(seat_id, table_no, seat_no, title, seatNotOccupied) {
        console.log("status : " + seatNotOccupied);
        this.rtc.changeSeatStatus(this.event.id,seat_id, table_no, seat_no, title, seatNotOccupied);
    },
  },
};
</script>

<style scoped>
.seat-setting:hover .seat-taken {
  visibility: visible !important;
}
input[type="number"] {
  -webkit-appearance: none;
}
</style>
