<template>
  <div v-if="showModal">
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :id="id"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Announcement</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label for="announcement" class="label">Announcement</label>
            <textarea
              type="textarea"
              class="form-control"
              name=""
              v-model="announcement"
              id="announcement"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="sendAnnoucement"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showModal", "id"],
  data: () => ({
    announcement: null,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    event() {
      return this.$store.state.auth.event;
    },
  },
  methods: {
    sendAnnoucement() {
      let data = {
        user_id: this.currentUser.user.id,
        event_id: this.event.id,
        announcement: this.announcement,
        API_URL: this.API_URL,
      };
      this.$store.dispatch("announcement/sendAnnoucement", data).then((r) => {
        console.log(r);
        if (r) {
          this.$emit("close");
          console.log(r);
          let data = {
            userId: this.$route.query.user_code,
            userImg:
              this.currentUser.user_details.avatar_base_url +
              "/" +
              this.currentUser.user_details.avatar_path,
            room: this.event.id,
            userName: this.currentUser.user_details.firstname,
            announcement: this.announcement,
          };
          this.$store.dispatch("announcement/submitAnnoucement", data);
          // this.$emit("submit_announcement", data);
        } else {
          alert("Try Again");
        }
      });
    },
  },
};
</script>
