<template>
  <div class="meeting-info">
    <h4>Meeting Information</h4>
    <div class="meeting-address">
      <h3>
        <strong>Looking Forward Consulting</strong> Team Room <br />by
        {{ currentUser.user_details.firstname }}, <br />Looking Forward
        Consulting <br />TIMEZONE:
        {{ currentUser.userTimeZone }}
      </h3>
    </div>
    <hr />
    <div class="meeting-agenda">
      <h2>MEETING AGENDA</h2>
      <span v-html="event.agenda"></span>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    event() {
      return this.$store.state.auth.event;
    },
  },
};
</script>
