<template>
  <div class="p-2 mt-3 border-radius-4 border-1 bg-white">
    <div class="participants-lobby">
      <div class="container">
        <!-- Hidden maintain list of lobby user and later copy that to carousel-->
        <div id="lobby-users" style="display:none">
          <div class="owl-stage"></div>
        </div>
        <div class="owl-carousel active_course owl-loaded owl-drag">
          <div class="owl-stage-outer" id="lobbyUsers">
            <div class="owl-stage-outer">
              <h6 id="participant-count">
                <span id="lobby_user_count">{{ lobbyUsers.length }}</span>
                PARTICIPANTS IN THE LOBBY
              </h6>
              <div
                v-for="(user, index) in lobbyUsers"
                :key="index"
                class="owl-stage"
                style="transform: translate3d(0px, 0px, 0px); transition: all 0s ease 0s; width: 94px;"
              >
                <div
                  class="owl-item active"
                  style="width: 76px; margin-right: 18px;"
                >
                  <div class="single_img active" id="user_section_1390">
                    <!-- <img
                      v-if="user.userImg != 'null/null'"
                      class="img-fluid"
                      :src="user.userImg"
                    /> -->
                    <!-- <span v-else>
                      {{ get_ini(user.userName) }}
                    </span> -->
                    <div class="name" id="lobby_user_1390">
                      {{ user.userName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Table />
</template>

<script>
import Table from "./table.vue";
export default {
  components: {
    Table,
  },
  computed: {
    lobbyUsers() {
      return this.$store.state.auth.lobbyUsers;
    },
  },
  methods: {
    get_ini(name) {
      let parts = name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
  },
  created() {
    this.$soketio.on("add_lobby_users", (data) => {
      this.lobbyUsers = data;
      console.log(data);
      console.log("all_lobby_users" + JSON.stringify(data));
    });
  },
};
</script>
