import AgoraRTC from "agora-rtc-sdk";
// import EventEmitter from "events";

export default class RTCClient {
  constructor() {
    // Options for joining a channel
    // this.option = {
    //   appId: "",
    //   channel: "",
    //   uid: "",
    //   token: "",
    // };
    // Agora client options
  //   let options = {
  //     appid: agora_app_id,
  //     channel: '',
  //     uid: '',
  //     token: '',
  //     userId: user_id,
  //     screenToken: '',
  //     screenUserId: ''
  // };
    this.client = null;
  //   this.localStream = null;
  //   this._eventBus = new EventEmitter();
  }

  changeSeatStatus(event_id ,seat_id, table_no, seat_no, title, seatNotOccupied) {
    console.log("status : " + event_id ,seat_id, table_no, seat_no, title, seatNotOccupied);
    this.client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});
    console.log(this.client);
    if (seatNotOccupied) {
      // emitJoinSeat(seat_id, table_no, seat_no, title, seatNotOccupied);
    } else {
      // options.channel = this.event.id + "-table-" + table_no;
      // emitJoinSeat(seat_id, table_no, seat_no, title, seatOccupied);
      // initiateDeviceTest();
    }
  }
}
