<template>
  <!-- Desktop View code here -->
  <div class="meeting-column">
    <h6 class="column-title">Meeting Agenda</h6>
    <div class="column-content">
      <span v-html="event.agenda"></span>
    </div>
  </div>
  <!-- Mobile View code here -->
  <div
    class="panel-group"
    id="accordionSingleClosed"
    role="tablist"
    aria-multiselectable="true"
  >
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingOne">
        <h4 class="panel-title">
          <a
            class="collapsed"
            role="button"
            data-toggle="collapse"
            href="#collapseItemCloseOne"
            aria-expanded="true"
            aria-controls="collapseItemCloseOne"
          >
            Meeting Agenda
          </a>
        </h4>
      </div>
      <div
        id="collapseItemCloseOne"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <div class="panel-body">
          <span v-html="event.agenda"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    event() {
      return this.$store.state.auth.event;
    },
  },
};
</script>