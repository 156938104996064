<template>
  <div>
    <section id="meeting-announcement-chat">
      <div id="meeting-tab">
        <div
          class="nav flex-column nav-pills nav-pills-custom"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link shadow active"
            id="v-pills-home-tab"
            data-toggle="pill"
            href="#v-pills-home"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            <small>Home</small>
          </a>
          <a
            class="nav-link shadow disabled d-none"
            id="v-pills-profile-tab"
            data-toggle="pill"
            href="#v-pills-profile"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <small>Profile</small>
          </a>
          <a
            class="nav-link shadow"
            id="v-pills-messages-tab"
            data-toggle="pill"
            href="#v-pills-messages"
            role="tab"
            aria-controls="v-pills-messages"
            aria-selected="false"
          >
            <small>Profile</small>
          </a>

          <a class="nav-link shadow" id="lobbyChatCollapse">
            <small>Chat</small>
          </a>
          <a class="nav-link shadow d-none" id="chatCollapse">
            <small>Chat</small>
          </a>
        </div>
        <div class="tab-content" id="v-pills-tabContent">
          <!--Done_Open-->
          <div
            class="tab-pane fade rounded show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <div
              class="
                d-flex
                flex-row
                sm-block-display
                justify-content-between
                bg-white
                border-radius-4 border-1
              "
            >
              <!--MEETING AGENDA-->
              <MeetingAgenda />
              <!--Announcement -->
              <div class="announcement-column text-center">
                <h6 class="column-title">
                  <button
                    class="btn-primary"
                    @click="showModal = true"
                   
                    title="Add Announcement"
                    v-if="currentUser.user_role == 'administrator'"
                  >
                    +
                  </button>
                  Announcements
                </h6>
                <div class="column-content" id="announcement">
                  <ul v-if="announcements.length > 0">
                    <li v-for="(data, index) in announcements" :key="index">
                      <div class="userProfile">
                        <img :src="data.userImg" class="img-fluid" />
                        <h5>
                          {{ data.userName }}
                        </h5>
                        <h6>
                          {{ data.date }}
                        </h6>
                      </div>
                      <div class="userContent">
                        <h3>
                          {{ data.announcement }}
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <span v-else>There are no announcements yet</span>
                </div>
              </div>
              <!--Chat Lobby-->
              <ChatLobby />
            </div>
            <!--lobby connected users-->
            <Lobby />
          </div>
          <!--Done_Close-->
          <div
            class="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <!--VIDEO SECTION-->
            <!-- <?php   if ($detect->isMobile() && !$detect->isTablet()) {
                    echo $this->render('video-call-chat-mobile', ['eventModel' => $eventModel, 'tableDataProvider' => $tableDataProvider]);
                } else {
                    echo $this->render('video-call-chat', ['eventModel' => $eventModel, 'tableDataProvider' => $tableDataProvider]);
                }
                                ?> -->
            <!--AGORA VIDEO CALL VIEW-->
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <div
              class="d-flex flex-row sm-block-display justify-content-between"
            >
              <!-- profile setting component -->
              <Profile />
              <!-- video setting component -->
              <VideoSettings />
              <!-- meeting information component -->
              <MeetingInfo />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--- end --->
    <!-- announcement model -->
    <Annoucement
      :showModal="showModal"
      v-if="showModal"
      :id="exampleModal"
      @close="showModal = false"
      @submit_announcement="submitAnnoucement"
    />
    <!--Back to Lobby Modal -->
    <div
      class="modal fade"
      id="Backlobby"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content popup-content">
          <div class="modal-header popup-header">
            <button
              type="button"
              class="close-popup"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="popup-title" id="myModalLabel">Back to Lobby</h4>
          </div>
          <div class="popup-body">
            <h3>
              This action will disconnect you from this table and take you to
              the virtual lobby. Do you want to continue?
            </h3>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-dismiss="modal"
            >
              NO
            </button>
            <button
              type="button"
              id="leave"
              class="btn btn-sm btn-success"
              onclick="endMeeting()"
              data-dismiss="modal"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Leave Meeting Modal -->
    <div
      class="modal fade"
      id="LeaveMeeting"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content popup-content">
          <div class="modal-header popup-header">
            <button
              type="button"
              class="close-popup"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="popup-title" id="myModalLabel">Leave Meeting</h4>
          </div>
          <div class="popup-body">
            <h3>
              This will exit you from the main meeting.<br />Are you sure you
              want to leave?
            </h3>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-dismiss="modal"
            >
              NO
            </button>
            <button
              type="button"
              id="leave"
              class="btn btn-sm btn-success"
              onclick="endMeeting();end()"
              data-dismiss="modal"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingAgenda from "./meeting_agenda.vue";
import ChatLobby from "./chat_lobby.vue";
import Lobby from "./lobby.vue";
import Annoucement from "./annoucement.vue";
import Profile from "./profile.vue";
import VideoSettings from "./video-settings.vue";
import MeetingInfo from "./meeting_info.vue";
// import Table from "./table.vue";
// import axios from "axios";
export default {
  components: {
    MeetingAgenda,
    ChatLobby,
    Lobby,
    Annoucement,
    Profile,
    VideoSettings,
    MeetingInfo,
  },
  data() {
    return {
      newLoaded: true,
      showModal: false,
      name: null,
      ready: false,
      info: [],
      connectionCount: 0,
      text: "",
    };
  },
  computed: {
    announcements() {
      return this.$store.state.announcement.announcements;
    },
    event() {
      return this.$store.state.auth.event;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.joinUser();
    this.verifyEventAndUser();
    this.$store.dispatch("announcement/receivedAnnoucement");
    this.$store.dispatch("auth/allLobbyUsers");

    this.getannouncements();
    this.$soketio.on("reconnect", function() {
      console.log("recooooo");
      // do not rejoin from here, since the this.$soketio.id token and/or rooms are still
      // not available.
      if (!this.newLoaded) {
        alert("--Reconnecting--");
        console.log("you have been reconnected");
      }
    });

    this.$soketio.on("connect", function() {
      // thats the key line, now register to the room you want.
        alert("--Connected--");

      if (!this.newLoaded) {
        alert("--Connected--");
      }
    });
    this.$soketio.on("connect_error", (err) => {
      console.log(err); // the request object
      console.log(err.code); // the error code, for example 1
      console.log(err.message); // the error message, for example "Session ID unknown"
      console.log(err.context); // some additional error context
    });
    // this.$soketio.on("connect_error", () => {
    //   this.newLoaded = false;
    //   console.log('test');
    //   alert("--Connection ERR--");
    // });
    this.$soketio.on("disconnect", function() {
      this.newLoaded = false;
      alert("--Disconnected ERR--");
      this.$soketio.connect();
    });
    let data = {
      room: this.event.id,
    };
    this.$soketio.emit("get_connected_user", data);
  },
  methods: {
    verifyEventAndUser() {
      var event_data = {
        event_code: this.$route.query.event_code,
        user_code: this.$route.query.user_code,
        API_URL: this.API_URL,
      };
      this.$store.dispatch("auth/verifyEvent", event_data);
    },
    joinUser() {
      let data2 = {
        userId: this.currentUser.user.id,
        room: this.event.id,
        userName: this.currentUser.user_details.firstname,
        userImg:
          this.currentUser.user_details.avatar_base_url +
          "/" +
          this.currentUser.user_details.avatar_path,
        time: this.getCurrentTime(),
      };
      this.$store.dispatch("auth/joinUser", data2);
      this.$store.dispatch("auth/userExist", data2);
    },
    getCurrentTime() {
      let date = new Date();
      let options = {
        timeZone: "UTC",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleTimeString("en-us", options);
    },

    getannouncements() {
      var data = {
        API_URL: this.API_URL,
        event_id: this.event.id,
      };
      this.$store.dispatch("announcement/getAnnouncements", data);
    },
  },
};
</script>
