<template>
  <div class="chat-column" id="lobby-chat">
    <button type="button" class="lobbyChatCollapse">Close</button>
    <h6 class="column-title">Chat</h6>

    <div id="chatbox" class="column-chat-content">
      <div
        class="chat-column-left"
        v-for="(chat, index) in messages"
        :key="index"
      >
        <div class="chat-name-date">
          <p>{{ chat.username }} ({{ chat.date }})</p>
        </div>
        <div class="chat-content-area">
          <p>{{ chat.message }}</p>
        </div>
      </div>
    </div>
    <!-- <div id="chatbox" class="column-chat-content">
        <?php if (!empty($meetChats)) {
            foreach ($meetChats as $chat) {
                if ($chat->user_id == Yii::$app->user->id) { ?>
                    <div class="chat-column-left">
                        <div class="chat-name-date">
                            <p>
                                <?= (Yii::$app->user->identity->userProfile->firstname) ?>
                                (<?php
                                $date = new DateTime($chat->created_at, new DateTimeZone("UTC"));
                                $date->setTimezone(new DateTimeZone($userTimeZone));
                                if ($date->format('Y-m-d') == date('Y-m-d')) {
                                    echo $date->format('h:i A');
                                } else {
                                    echo $date->format('m/d h:i A');
                                }
                                ?>)
                            </p>
                        </div>
                        <div class="chat-content-area">
                            <p><?= Html::encode($chat->message) ?></p>
                        </div>
                    </div>
                <?php } else if($chat->anonymous === 0){
                    $user = User::find()->where(['id' => $chat->user_id])->one(); ?>
                        <div class="chat-column-left">
                            <div class="chat-name-date">
                                <p>
                                    <?= ($user->userProfile->firstname) ?>
                                    (<?php
                                    $date = new DateTime($chat->created_at, new DateTimeZone("UTC"));
                                    $date->setTimezone(new DateTimeZone($userTimeZone));
                                    if ($date->format('Y-m-d') == date('Y-m-d')) {
                                        echo $date->format('h:i A');
                                    } else {
                                        echo $date->format('m/d h:i A');
                                    }
                                    ?>)
                                </p>
                            </div>
                            <div class="chat-content-area">
                                <p><?= Html::encode($chat->message) ?></p>
                            </div>
                        </div>
                <?php } else {
                    ?>
                    <div class="chat-column-left">
                        <div class="chat-name-date">
                            <p>
                                <?= ($chat->anonymous_name) ?>
                                (<?php
                                $date = new DateTime($chat->created_at, new DateTimeZone("UTC"));
                                $date->setTimezone(new DateTimeZone($userTimeZone));
                                if ($date->format('Y-m-d') == date('Y-m-d')) {
                                    echo $date->format('h:i A');
                                } else {
                                    echo $date->format('m/d h:i A');
                                }
                                ?>)
                            </p>
                        </div>
                        <div class="chat-content-area">
                            <p><?= Html::encode($chat->message) ?></p>
                        </div>
                    </div>
                <?php }
            }
        } ?>
    </div> -->
    <form class="msger-inputarea">
      <input
        id="texts"
        type="text"
        class="msger-input"
        contentEditable
        placeholder="Say something..."
        v-model="msg"
      />
      <button
        v-if="msg"
        id="send"
        @click="sendMessage"
        type="button"
        class="msger-send-btn"
      >
        <!--             <i class="fas fa-laugh" style="margin: 5px"></i>-->
        <!-- <i class="fas fa-paper-plane"></i> -->
        SEND
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "",
    };
  },
  computed: {
    joined() {
      return this.$store.state.auth.joined;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    event() {
      return this.$store.state.auth.event;
    },
    messages() {
      return this.$store.state.chat.messages;
    },
  },
  mounted() {
    this.$store.dispatch("chat/receivedMsg");
    this.getAllChat();
  },
  methods: {
    getAllChat() {
      var data = {
        API_URL: this.API_URL,
        event_id: this.event.id,
      };
      this.$store.dispatch("chat/getChats", data);
    },
    sendMessage() {
      this.addMessage();
      this.msg = "";
    },
    addMessage() {
      console.log(this.currentUser);
      const message = {
        date: new Date(),
        userId: this.currentUser.user.id,
        username: this.currentUser.user_details.firstname,
        room: this.event.id,
        message: this.msg,
      };
      let data = {
        API_URL: this.API_URL,
        date: new Date(),
        userId: this.currentUser.user.id,
        username: this.currentUser.user_details.firstname,
        room: this.event.id,
        message: this.msg,
      };
      this.$store.dispatch("chat/saveChatInDb", data).then((r) => {
        if (r) {
          this.$store.dispatch("chat/sendMsg", message);
        } else {
          alert("Try Again");
        }
      });
    },
  },
};
</script>
