<template>
  <p>test</p>
  <div v-if="showModal">
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :id="id"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="$emit('close')"
            >
              &times;
            </button>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-8 offset-2 col-md-12 col-12">
                <div class="test-block" id="test-camera">
                  <video controls="" width="100%">
                    <source src="video/videoplayback.mp4" type="video/mp4" />
                    <source src="video/videoplayback.ogg" type="video/ogg" />
                  </video>
                </div>
                <div class="test-content-block">
                  <div>
                    <h3>Test your set up</h3>
                    <p>
                      Others will be able to see and hear you when you join a
                      table.
                    </p>
                  </div>

                  <div class="slidecontainer">
                    <input
                      type="range"
                      min="1"
                      max="1000"
                      value="0"
                      class="slider"
                      id="test-mic-output"
                    />
                  </div>
                </div>
                <div class="test-block-action">
                  <div class="action-column camera">
                    <select class="selectDropdown" id="camera-dropdown">
                      <option>Default Camera....</option>
                    </select>
                  </div>
                  <div class="action-column audio">
                    <select class="selectDropdown" id="mic-dropdown">
                      <option
                        ><i class="fa fa-user"></i> Default Mic....</option
                      >
                    </select>
                  </div>
                </div>
                <div class="action-btn">
                  <button class="btn btn-md btn-light-gray" id="mute-video">
                    <i class="fa fa-video" id="video-icon"></i>
                  </button>
                  <button class="btn btn-md btn-light-gray" id="mute-mic">
                    <i class="fa fa-microphone" id="mic-icon"></i>
                  </button>
                </div>
                <div class="text-center mt-3">
                  <button
                    type="button"
                    class="btn btn-theme mt-3"
                    onclick="startVideoCall()"
                  >
                    Enter Meeting
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["showModal", "id"],
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style scoped>
.slidecontainer {
  width: 100%; /* Width of the outside container */
}
.action-btn {
  padding-left: 40%;
  margin-top: 10px;
}
/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: #edebe7; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.3s; /* 0.2 seconds transition on hover */
  transition: opacity 0.3s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 5px; /* Set a specific slider handle width */
  height: 5px; /* Slider handle height */
  background: #3224af; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 5px; /* Set a specific slider handle width */
  height: 5px; /* Slider handle height */
  background: #3224af; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
</style>
