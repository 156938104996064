<template>
  <div class="audio-video-setting">
    <h4>Profile Settings</h4>
    <div class="avatar-upload">
      <!-- //preview image if selected -->
      <img
        v-if="previewImage"
        width="200"
        :src="previewImage"
        alt="..."
        class="rounded-circle"
      />
      <!-- //orginal image -->
      <img v-else :src="imageUrl" alt="..." class="rounded-circle" />
    </div>
    <input
      type="file"
      accept="image/*"
      ref="file"
      @change="selectImage"
      class="form-control"
    />

    <h4>Name: {{ currentUser.user_details.firstname }}</h4>
    <button class="btn btn-xs btn-success" @click="uploadProfileImage()">
      Save Changes
    </button>
    <!-- <?php echo Html::submitButton(Yii::t('backend', 'Save Changes'), ['class' => 'btn btn-xs btn-success']); ?> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      imageUrl: null,
      image: null,
      previewImage: null,
      currentImage: null,
      ready: false,
      info: [],
      connectionCount: 0,
      text: "",
    };
  },
  computed: {
    event() {
      return this.$store.state.auth.event;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    uploadProfileImage() {
      let formData = new FormData();
      formData.append("file", this.currentImage);
      return axios.post(`${this.API_URL}/user/upload-profile-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
  methods: {
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      console.log(this.currentImage);
      this.previewImage = URL.createObjectURL(this.currentImage);
    },
  },
  created() {
    this.imageUrl = `${this.currentUser.user_details.avatar_base_url}/${this.currentUser.user_details.avatar_path}`;
  },
};
</script>

<style>
.avatar-upload .upload-kit-item.image > img {
  border-radius: 50% !important;
}
.upload-kit ul {
  height: auto;
}
.avatar-upload .upload-kit .upload-kit-input,
.avatar-upload .upload-kit .upload-kit-item {
  float: none;
  border-radius: 50% !important;
  /*border: none;*/
}
</style>
